// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app\\utils\\elementNotContainedByClickTarget.ts"
);
import.meta.hot.lastModified = "1718604621030.3887";
}
// REMIX HMR END

export function elementNotContainedByClickTarget(element: HTMLElement) {
	let current: HTMLElement | null = element

	while (current) {
		if (
			[
				'a',
				'button',
				'details',
				'input',
				'select',
				'textarea',
				'area',
				'audio',
				'iframe',
				'img',
				'label',
				'link',
				'object',
				'summary',
				'video',
			].includes(element.tagName?.toLowerCase()) ||
			element.isContentEditable ||
			element.hasAttribute('tabindex')
		) {
			return true
		}

		current = current.parentElement
	}

	return false
}
